import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useFormContext } from "../FormContext";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../shadcn-ui/Button";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { useAppContext } from "../../../../contexts/AppContext";

export interface AdminSummaryOptionsProps {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
}

const AdminSummaryOptions: React.FC<AdminSummaryOptionsProps> = ({ values, errors, touched, setFieldValue, onNext, onPrevious, isValid, dirty }) => {
	const { instageUser } = useAppContext();
	const { updateFormValues } = useFormContext();
	const [additionalEmails, setAdditionalEmails] = useState<string[]>(() => {
		const initialEmails = values.additionalReportRecipients ? values.additionalReportRecipients.split(",").map((e: string) => e.trim()) : [];
		return values.reportFrequency !== "noReports" && instageUser && !initialEmails.includes(instageUser.email)
			? [...initialEmails, instageUser.email]
			: initialEmails;
	});
	// const [emailDeliveryOptions, setEmailDeliveryOptions] = useState<string>(() => {
	// 	return values.emailDeliveryOptions || ""; // Initialize as a string
	// });
	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			addEmail();
		}
	};

	useEffect(() => {
		if (values.includeCSV && values.includePDF) {
			setFieldValue("emailDeliveryOptions", "csv,pdf");
		} else if (values.includeCSV) {
			setFieldValue("emailDeliveryOptions", "csv");
		} else if (values.includePDF) {
			setFieldValue("emailDeliveryOptions", "pdf");
		} else {
			setFieldValue("emailDeliveryOptions", "");
		}
		console.log("assignment payload", values);
	}, [values.includeCSV, values.includePDF]);

	const addEmail = () => {
		const trimmedEmail = inputValue.trim();
		if (trimmedEmail && !additionalEmails.includes(trimmedEmail)) {
			const updatedEmails = [...additionalEmails, trimmedEmail];
			setAdditionalEmails(updatedEmails);
			setFieldValue("additionalReportRecipients", updatedEmails.join(","));
			updateFormValues({ ...values, additionalReportRecipients: updatedEmails.join(",") });
			setInputValue("");
		}
		console.log("additionalEmails", additionalEmails);
	};

	const removeEmail = (emailToRemove: string) => {
		const updatedEmails = additionalEmails.filter((email) => email !== emailToRemove);
		setAdditionalEmails(updatedEmails);
		setFieldValue("additionalReportRecipients", updatedEmails.join(","));
		updateFormValues({ ...values, additionalReportRecipients: updatedEmails.join(",") });
	};

	const updateEmailList = (newReportFrequency: string) => {
		let updatedEmails = [...additionalEmails];
		if (newReportFrequency !== "noReports" && instageUser && !updatedEmails.includes(instageUser.email)) {
			updatedEmails.push(instageUser.email);
		} else if (newReportFrequency === "noReports" && instageUser) {
			updatedEmails = updatedEmails.filter((email) => email !== instageUser.email);
		}
		setAdditionalEmails(updatedEmails);
		setFieldValue("additionalReportRecipients", updatedEmails.join(","));
		updateFormValues({ ...values, additionalReportRecipients: updatedEmails.join(",") });
	};

	// const updateEmailDeliveryOptions = (option: string, isChecked: boolean) => {
	// 	setEmailDeliveryOptions((prevOptions) => {
	// 		const currentOptions = prevOptions ? prevOptions.split(",") : []; // Split the string into an array
	// 		if (isChecked) {
	// 			return [...currentOptions, option].join(","); // Join back into a string
	// 		} else {
	// 			return currentOptions.filter((opt) => opt !== option).join(","); // Join back into a string
	// 		}
	// 	});
	// 	setFieldValue("emailDeliveryOptions", emailDeliveryOptions); // Use the string directly
	// };

	// Add this effect to handle changes in reportFrequency
	useEffect(() => {
		updateEmailList(values.reportFrequency);
	}, [values.reportFrequency]);

	const isPublished = values.isPublished;

	return (
		<div className="space-y-8">
			<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
				<h2 className="text-2xl font-semibold text-gray-800 mb-2">Admin Summary Options</h2>
				<p className="text-sm text-gray-600 mb-6">
					This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date.
				</p>

				<label className="font-medium text-gray-800">When would you like to receive emails of the admin summaries?</label>
				<div className="space-y-4 mt-4">
					<label className="flex items-center">
						<Field
							type="radio"
							name="reportFrequency"
							value="afterEachDueDate"
							className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setFieldValue("reportFrequency", e.target.value);
								updateEmailList(e.target.value);
							}}
						/>
						<span className="ml-2 text-sm text-gray-700 flex items-center">
							Send a report <span className="font-bold mx-1">24 hours after each</span> due date has passed
							<CustomTooltip content="This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date." />
						</span>
					</label>
					<label className="flex items-center">
						<Field
							type="radio"
							name="reportFrequency"
							value="afterFinalDueDate"
							className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setFieldValue("reportFrequency", e.target.value);
								updateEmailList(e.target.value);
							}}
						/>
						<span className="ml-2 text-sm text-gray-700 flex items-center">
							Send a report <span className="font-bold mx-1">only after the final</span> due date has passed
							<CustomTooltip content="This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date." />
						</span>
					</label>
					<label className="flex items-center">
						<Field
							type="radio"
							name="reportFrequency"
							value="noReports"
							className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setFieldValue("reportFrequency", e.target.value);
								updateEmailList(e.target.value);
							}}
						/>
						<span className="ml-2 text-sm text-gray-700">Do not send email reports</span>
					</label>
				</div>

				{values.reportFrequency && values.reportFrequency !== "noReports" && (
					<div className="mt-4">
						<h4 className="font-medium text-gray-800">What do you want included in the email?</h4>
						<div className="mt-4 space-y-2">
							<label className="flex items-center">
								<input
									type="checkbox"
									checked={true}
									readOnly
									className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af] cursor-not-allowed opacity-50"
								/>
								<span className="ml-2 text-sm text-gray-700">Link to admin summary</span>
							</label>
							<label className="flex items-center">
								<Field
									type="checkbox"
									name="includeCSV"
									className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af]"
									// onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									// 	updateEmailDeliveryOptions("csv", e.target.checked);
									// }}
								/>
								<span className="ml-2 text-sm text-gray-700">Include a CSV file of the student activity in the email</span>
								<CustomTooltip
									content="This includes a list of the students and their activity for each assigned call."
									image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/csvGif_2.gif"
								/>
							</label>
							<label className="flex items-center">
								<Field
									type="checkbox"
									name="includePDF"
									className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af]"
									// onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									// 	updateEmailDeliveryOptions("pdf", e.target.checked);
									// }}
								/>
								<span className="ml-2 text-sm text-gray-700">Include a PDF summary in the email</span>
								<CustomTooltip
									content="This includes a PDF version of the admin summary."
									image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/pdfGif.gif"
								/>
							</label>
						</div>

						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700 mb-2">
								Do you want any other colleagues to receive copies of the activity reports?
							</label>
							<div className="flex flex-wrap gap-2 p-2 border rounded-lg focus-within:border-[#00a9af]">
								{additionalEmails.map((email, index) => (
									<div key={index} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
										<span className="text-sm">{email}</span>
										<button type="button" onClick={() => removeEmail(email)} className="ml-2 text-gray-500 hover:text-gray-700">
											<XCircleIcon className="h-4 w-4" />
										</button>
									</div>
								))}
								<input
									type="text"
									value={inputValue}
									onChange={handleInputChange}
									onKeyDown={handleInputKeyDown}
									onBlur={addEmail}
									className="flex-grow outline-none"
									placeholder="Enter email addresses"
								/>
							</div>
						</div>
					</div>
				)}
				<p className="mt-2 text-sm text-gray-600 italic">
					Note: You can always log in to the dashboard to view assignment activity at any time.
				</p>
				<ErrorMessage name="reportFrequency" component="div" className="mt-2 text-sm text-red-600" />
			</div>

			<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
				<h2 className="text-2xl font-semibold text-gray-800 mb-6">Activity Summary Content</h2>

				<label className="flex items-center">
					<Field
						disabled={isPublished}
						type="checkbox"
						name="includeTranscript"
						className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af]"
					/>
					<span className="ml-2 text-sm text-gray-700">Include student transcript</span>
					<CustomTooltip content="When enabled, the student transcript will be included in the admin summary." />
				</label>
				<label className="flex items-center">
					<Field
						disabled={isPublished}
						type="checkbox"
						name="includeAudio"
						className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af]"
					/>
					<span className="ml-2 text-sm text-gray-700">Include student audio recording</span>
					<CustomTooltip content="When enabled, the student audio recording will be included in the admin summary." />
				</label>
				<label className="flex items-center">
					<Field
						disabled={isPublished}
						type="checkbox"
						name="includeUserIdentification"
						className="form-checkbox h-4 w-4 text-[#00a9af] rounded focus:ring-[#00a9af]"
						defaultChecked={true}
					/>
					<span className="ml-2 text-sm text-gray-700">Include student personal information</span>
					<CustomTooltip content="When enabled, the student personal information will be included in the admin summary." />
				</label>

				<p className="text-sm text-gray-600 italic">
					Note: If either option is selected the student will be informed that the data will be included in their reflection submission.
				</p>
			</div>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					variant="secondary"
					onClick={() => {
						updateFormValues(values);
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					onClick={() => {
						updateFormValues(values);
						onNext();
					}}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

export default AdminSummaryOptions;
