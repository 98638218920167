import axios from "axios";
import { SessionView } from "../types/SessionView";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";

export enum VapiCallType {
	InboundPhoneCall = "inboundPhoneCall",
	OutboundPhoneCall = "outboundPhoneCall",
	WebCall = "webCall"
}
export interface SessionStats {
	totalDuration: string;
	avgDuration: string;
	sessionsStarted: number;
	sessionsSubmitted: number;
	uniqueStudents: number;
}
export class SessionService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sessions`;

	static async startSession(
		userId: string,
		vapiCallType: VapiCallType,
		assignmentId?: string,
		scheduleId?: string,
		voiceProvider?: string,
		voiceId?: string,
		assistantName?: string
	): Promise<SessionView> {
		try {
			const response = await axios.post(`${this.baseUrl}/start-session`, {
				userId,
				assignmentId,
				scheduleId,
				voiceProvider,
				voiceId,
				assistantName,
				vapiCallType
			});
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `start session ${userId} ${vapiCallType} ${assignmentId} ${scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	static async updateSession(sessionId: string, vapiCallId: string): Promise<SessionView> {
		try {
			const response = await axios.put(`${this.baseUrl}/update-session/${sessionId}`, { vapiCallId });
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update session ${sessionId} ${vapiCallId}`);
			throw new Error(errorMessage);
		}
	}

	static async getSession(sessionId: string): Promise<SessionView> {
		try {
			const response = await axios.get(`${this.baseUrl}/session/${sessionId}`);
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get session ${sessionId}`);
			throw new Error(errorMessage);
		}
	}

	static async getPublicSession(sessionId: string): Promise<SessionView> {
		try {
			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api-public/sessions`, {
				params: {
					sessionId
				}
			});
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					if (error.response.status === 403) {
						throw new Error("Access denied for non-inbound sessions");
					} else if (error.response.status === 404) {
						throw new Error("Session not found");
					}
				}
			}
			console.error("Error getting session:", error);
			throw new Error("Failed to get session");
		}
	}

	static async getSessionEmail(authToken: string): Promise<SessionView> {
		console.log("");
		console.log("");
		console.log("");
		console.log(authToken);
		console.log(`${this.baseUrl}/email`);
		try {
			const response = await axios.get(`${this.baseUrl}/email`, { headers: { Authorization: `Bearer ${authToken}` } });
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			console.error("Error getting session:", error);
			throw error;
		}
	}

	static async getUserSessionsViewByUserIdAndAssignmentId(userId: string, assignmentId: string): Promise<SessionView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/user-sessions-view/user/${userId}/assignment/${assignmentId}`);
			console.log(response.data);

			return plainToInstance(SessionView, response.data as SessionView[]);
		} catch (error) {
			console.error("Error getting user session view:", error);
			throw error;
		}
	}

	static async getAllSessionsByAssignmentId(
		assignmentId: string,
		page?: number,
		limit?: number
	): Promise<{ totalPages: number; total: number; pages: number[]; limit: number; sessions: SessionView[] }> {
		try {
			const params = new URLSearchParams();
			if (page !== undefined) params.append("page", page.toString());
			if (limit !== undefined) params.append("limit", limit.toString());

			const response = await axios.get(`${this.baseUrl}/assignment/${assignmentId}`, { params });
			return {
				totalPages: response.data.totalPages,
				total: response.data.total,
				pages: response.data.pages,
				limit: response.data.limit,
				sessions: plainToInstance(SessionView, response.data.sessions as SessionView[])
			};
		} catch (error) {
			console.error("Error getting sessions by assignment ID:", error);
			throw error;
		}
	}

	static async getAllSessionsByUserId(
		userId: string,
		page?: number,
		limit?: number
	): Promise<{ totalPages: number; total: number; pages: number[]; limit: number; sessions: SessionView[] }> {
		try {
			const response = await axios.get(`${this.baseUrl}/user/${userId}`, { params: { page, limit } });
			return {
				totalPages: response.data.totalPages,
				total: response.data.total,
				pages: response.data.pages,
				limit: response.data.limit,
				sessions: plainToInstance(SessionView, response.data.sessions as SessionView[])
			};
		} catch (error) {
			console.error("Error getting sessions by user ID:", error);
			throw error;
		}
	}

	static async getSessionStatsByAssignmentId(assignmentId: string): Promise<SessionStats> {
		try {
			const response = await axios.get(`${this.baseUrl}/stats/assignment/${assignmentId}`);
			return response.data;
		} catch (error) {
			console.error("Error getting session stats by assignment ID:", error);
			throw error;
		}
	}

	static async getSessionStatsByScheduleId(scheduleId: string): Promise<SessionStats> {
		try {
			const response = await axios.get(`${this.baseUrl}/stats/schedule/${scheduleId}`);
			return response.data;
		} catch (error) {
			console.error("Error getting session stats by schedule ID:", error);
			throw error;
		}
	}

	static async getAllSessionsByClientId(
		page?: number,
		limit?: number
	): Promise<{ totalPages: number; total: number; pages: number[]; limit: number; sessions: SessionView[]; stats: SessionStats }> {
		console.log(`fetching sessions for client ${page} ${limit}`);
		try {
			const response = await axios.get(`${this.baseUrl}/`, { params: { page, limit } });
			console.log("");
			console.log(response.data);
			console.log("");
			return {
				totalPages: response.data.totalPages,
				total: response.data.total,
				pages: response.data.pages,
				limit: response.data.limit,
				sessions: plainToInstance(SessionView, response.data.sessions as SessionView[]),
				stats: response.data.stats
			};
		} catch (error) {
			console.error("Error getting all sessions:", error);
			throw error;
		}
	}

	static async getAllSessionsDebug(
		page?: number,
		limit?: number
	): Promise<{ totalPages: number; total: number; pages: number[]; limit: number; sessions: SessionView[]; stats: SessionStats }> {
		try {
			const response = await axios.get(`${this.baseUrl}/debug`, { params: { page, limit } });
			// console.log("*****************");
			// console.log(response.data);
			// console.log(response.data.page);
			// console.log("*****************");
			const sessions = response.data.sessions || [];
			return {
				totalPages: response.data.totalPages,
				total: response.data.total,
				pages: response.data.pages,
				limit: response.data.limit,
				sessions: plainToInstance(SessionView, sessions as SessionView[]),
				stats: response.data.stats
			};
		} catch (error) {
			console.error("Error getting all sessions:", error);
			throw error;
		}
	}

	static async regenerateSummary(sessionId: string): Promise<SessionView> {
		try {
			const response = await axios.post(`${this.baseUrl}/regenerate-summary/${sessionId}`);
			return plainToInstance(SessionView, response.data);
		} catch (error) {
			console.error("Error regenerating summary:", error);
			throw error;
		}
	}

	static async getSubmittedSessionsByScheduleId(scheduleId: string): Promise<SessionView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/submitted/schedule/${scheduleId}`);
			return plainToInstance(SessionView, response.data as SessionView[]);
		} catch (error) {
			console.error("Error getting submitted sessions by schedule ID:", error);
			throw error;
		}
	}
}
