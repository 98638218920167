import React, { useRef, useEffect, useState } from "react";

interface AudioVisualizerProps {
	isVapiActive: boolean;
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ isVapiActive }) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const audioContextRef = useRef<AudioContext | null>(null);
	const analyserRef = useRef<AnalyserNode | null>(null);
	const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
	const animationFrameRef = useRef<number | null>(null);
	const [isCapturing, setIsCapturing] = useState(false);

	useEffect(() => {
		if (isVapiActive) {
			startCapture();
		} else {
			stopCapture();
		}
		return () => stopCapture();
	}, [isVapiActive]);

	const startCapture = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

			audioContextRef.current = new AudioContext();
			analyserRef.current = audioContextRef.current.createAnalyser();
			analyserRef.current.fftSize = 1024;
			sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
			sourceRef.current.connect(analyserRef.current);

			setIsCapturing(true);
			drawVisualizer();
		} catch (error: any) {
			console.error("Error starting capture:", error);
		}
	};

	const stopCapture = () => {
		if (sourceRef.current) {
			sourceRef.current.disconnect();
			sourceRef.current = null;
		}
		if (audioContextRef.current && audioContextRef.current.state !== "closed") {
			audioContextRef.current.close().catch((err) => console.error("Error closing AudioContext:", err));
		}
		if (animationFrameRef.current) {
			cancelAnimationFrame(animationFrameRef.current);
			animationFrameRef.current = null;
		}
		setIsCapturing(false);
	};

	const drawVisualizer = () => {
		if (!analyserRef.current || !canvasRef.current) return;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		const WIDTH = canvas.width;
		const HEIGHT = canvas.height;
		const MIDDLE = HEIGHT / 2;

		const bufferLength = analyserRef.current.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		const draw = () => {
			animationFrameRef.current = requestAnimationFrame(draw);

			analyserRef.current!.getByteFrequencyData(dataArray);

			ctx.clearRect(0, 0, WIDTH, HEIGHT);

			const barWidth = 2;
			const gap = 1;
			const totalBars = Math.floor(WIDTH / (barWidth + gap));
			const step = Math.ceil(bufferLength / totalBars);

			for (let i = 0; i < totalBars; i++) {
				const dataIndex = i * step;
				// Increase the multiplier to make bars taller, adjusted for the new height
				let barHeight = (dataArray[dataIndex] / 255) * (HEIGHT / 2 - 2) * 1.5;

				// Ensure barHeight doesn't exceed half of the canvas height
				barHeight = Math.min(barHeight, HEIGHT / 2 - 2);

				// Ensure barHeight is always non-negative
				barHeight = Math.max(barHeight, 0);

				const x = i * (barWidth + gap);

				// Use a single color instead of gradient
				ctx.fillStyle = barHeight > 0 ? "#41c1ca" : "transparent";

				// Draw upper bar
				if (barHeight > 0) {
					ctx.fillRect(x, MIDDLE - barHeight, barWidth, barHeight);
				}

				// Draw lower bar (mirrored)
				if (barHeight > 0) {
					ctx.fillRect(x, MIDDLE, barWidth, barHeight);
				}
			}
		};

		draw();
	};

	return <canvas ref={canvasRef} width={window.innerWidth} height={80} className="w-full h-full" style={{ imageRendering: "pixelated" }} />;
};

export default AudioVisualizer;
