import ReportCard from "./ReportCard";
import { SessionView } from "../../types/SessionView";
import {
	ArrowTrendingUpIcon,
	BoltIcon,
	ChatBubbleLeftIcon,
	StarIcon,
	FlagIcon,
	ClipboardDocumentCheckIcon,
	BookOpenIcon,
	ClipboardDocumentListIcon,
	LightBulbIcon
} from "@heroicons/react/24/solid";

export function normalizeCompetencyName(name: string): string {
	return name.toLowerCase().replace(/[-\s]/g, "");
}

export function getInitialComment(selectedUserSession: SessionView, title: string): string {
	return selectedUserSession.comments?.find((comment) => comment.label === title)?.content || "";
}

export function renderOverview(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Overview";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<BookOpenIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.overview && <p className="text-gray-600">{selectedUserSession.overview}</p>}
		</ReportCard>
	);
}

export function renderExpectations(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Expectations for this Experience";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.expectations && selectedUserSession.expectations.content && (
				<p className="text-gray-600">{selectedUserSession.expectations.content}</p>
			)}
			{selectedUserSession.expectations && selectedUserSession.expectations.outlook && (
				<p className="mt-4">
					<span className="font-semibold text-gray-800 mb-2">Outlook: </span>
					<span
						className={`px-2 py-1 rounded-full text-sm font-medium ${
							selectedUserSession.expectations.outlook === "positive"
								? "bg-green-100 text-green-800"
								: selectedUserSession.expectations.outlook === "negative"
								? "bg-red-100 text-red-800"
								: "bg-yellow-100 text-yellow-600"
						}`}
					>
						{selectedUserSession.expectations.outlook.charAt(0).toUpperCase() + selectedUserSession.expectations.outlook.slice(1)}
					</span>
				</p>
			)}
		</ReportCard>
	);
}

export function renderCompetencyProgress(
	selectedUserSession: SessionView,
	userId: string,
	competencyData: { [key: string]: number[] },
	onAddComment?: (content: string, title: string) => void
) {
	if (selectedUserSession.competencies && selectedUserSession.competencies.length > 0) {
		return (
			<div className="my-12">
				{selectedUserSession.competencies.map((competency) => {
					const normalizedName = normalizeCompetencyName(competency.name);
					const competencyProgressItem = selectedUserSession.competencyProgress.find(
						(progress) => normalizeCompetencyName(progress.name) === normalizedName
					);
					const assessments = competencyData[normalizedName] || [];
					const title = competency.name.charAt(0).toUpperCase() + competency.name.slice(1);
					const initialComment = getInitialComment(selectedUserSession, `Competency Progress: ${title}`);
					const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
					return (
						<ReportCard
							key={`competency-progress-${normalizedName}`}
							title={`Competency Progress: ${title}`}
							icon={<BoltIcon className="h-5 w-5 text-[#16013e]" />}
							reportScoreData={assessments}
							lineColor="#d4d4d8"
							currentScore={Number(competencyProgressItem?.assessment)}
							onAddComment={(content, title) => {
								if (onAddComment) {
									onAddComment(content, `Competency Progress: ${title}`);
								}
							}}
							initialComment={initialComment}
							canComment={canComment}
						>
							{competencyProgressItem && competencyProgressItem.summary && (
								<p className="text-gray-600">{competencyProgressItem.summary}</p>
							)}
						</ReportCard>
					);
				})}
			</div>
		);
	}
	return null;
}

export function renderSatisfaction(
	selectedUserSession: SessionView,
	userId: string,
	satisfactionData?: number[] | undefined,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Satisfaction";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
			reportScoreData={satisfactionData}
			lineColor="#d4d4d8"
			currentScore={selectedUserSession.satisfaction?.score}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.reason && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Reason for Rating:</span>
					{selectedUserSession.satisfaction.reason}
				</p>
			)}
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.best && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Most Valuable Aspects of Course:</span>
					{selectedUserSession.satisfaction.best}
				</p>
			)}
			{selectedUserSession.satisfaction && selectedUserSession.satisfaction.worst && (
				<p className="text-gray-600 mb-4">
					<span className="font-semibold text-gray-800 mr-2">Least Valuable Aspects of Course:</span>
					{selectedUserSession.satisfaction.worst}
				</p>
			)}
		</ReportCard>
	);
}

export function renderGoalProgress(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	if (selectedUserSession.goalProgress && selectedUserSession.goalProgress.length > 0) {
		return (
			<>
				{selectedUserSession.goalProgress.map((goal, index) => {
					const title = `Goal Progress: ${goal.description}`;
					const initialComment = getInitialComment(selectedUserSession, title);
					const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
					return (
						<ReportCard
							key={index}
							title={title}
							icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
							onAddComment={(content, title) => {
								if (onAddComment) {
									onAddComment(content, title);
								}
							}}
							initialComment={initialComment}
							canComment={canComment}
							hideButtons={hideButtons}
						>
							<div className="mb-4">
								{goal.goal_status && (
									<>
										<h4 className="font-semibold text-gray-800 mb-2">Goal Status</h4>
										<p
											className={`text-sm font-medium px-3 py-1 rounded-full inline-block ${
												goal.goal_status === "Yes"
													? "bg-green-100 text-green-800"
													: goal.goal_status === "Partial"
													? "bg-yellow-100 text-yellow-600"
													: "bg-red-100 text-red-800"
											}`}
										>
											{goal.goal_status}
										</p>
									</>
								)}
							</div>

							{goal.summary && <p className="text-gray-600">{goal.summary}</p>}
						</ReportCard>
					);
				})}
			</>
		);
	}

	const title = `Goal Progress:`;
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			key={0}
			title={title}
			icon={<ArrowTrendingUpIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			<p className="text-gray-600"></p>
		</ReportCard>
	);
}

export function renderGoals(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Goals Before Next Call";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	return (
		<ReportCard
			title={title}
			icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.goals && Array.isArray(selectedUserSession.goals) && selectedUserSession.goals.length > 0 && (
				<>
					{selectedUserSession.goals.map((goal, index) => (
						<div key={index} className="mb-4">
							{goal.description && (
								<p className="text-gray-800 mb-2">
									<span className="font-semibold">Goal {index + 1}:</span> {goal.description}
								</p>
							)}
						</div>
					))}
				</>
			)}
		</ReportCard>
	);
}

export function renderFinalReview(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Review of Reflection Process";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.finalReview && (
				<>
					{selectedUserSession.finalReview.takeaways && (
						<>
							<div className="mt-4">
								<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
								{Array.isArray(selectedUserSession.finalReview.takeaways) &&
									selectedUserSession.finalReview.takeaways.map((takeaway, index) => (
										<div key={index} className="mb-4 p-2 ml-2">
											{takeaway.point && <p className="text-gray-600">Point: {takeaway.point}</p>}
											{takeaway.example && <p className="text-gray-600">Example: {takeaway.example}</p>}
										</div>
									))}
							</div>
						</>
					)}
					{selectedUserSession.finalReview.growth && (
						<>
							<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
							<p className="text-gray-600 ml-4">{selectedUserSession.finalReview.growth}</p>
						</>
					)}
					{selectedUserSession.finalReview.feedback && (
						<div className="mt-4">
							<h3 className="font-semibold text-gray-800 mb-4">Feedback</h3>
							<div className="space-y-3 ml-4">
								<div>
									<span className="text-gray-700">Valuable Aspects: </span>
									{selectedUserSession.finalReview.feedback.valuableAspects && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.valuableAspects}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Contribution: </span>
									{selectedUserSession.finalReview.feedback.contribution && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.contribution}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Suggestions: </span>
									{selectedUserSession.finalReview.feedback.suggestions && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.suggestions}</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Preferred Method: </span>
									{selectedUserSession.finalReview.feedback.preferredMethod && (
										<span className="text-gray-600">
											{selectedUserSession.finalReview.feedback.preferredMethod.charAt(0).toUpperCase() +
												selectedUserSession.finalReview.feedback.preferredMethod.slice(1)}
										</span>
									)}
								</div>
								<div>
									<span className="text-gray-700">Rating: </span>
									{selectedUserSession.finalReview.feedback.rating && (
										<span className="text-gray-600">{selectedUserSession.finalReview.feedback.rating.toString()}/10</span>
									)}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</ReportCard>
	);
}

export function renderPurpose(selectedUserSession: SessionView, userId: string, onAddComment?: (content: string, title: string) => void) {
	const title = "Purpose";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();
	return (
		<ReportCard
			title={title}
			icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
		>
			{selectedUserSession.purpose && (
				<>
					{selectedUserSession.purpose.description && (
						<p className="text-gray-600 mb-4">
							<span className="font-semibold text-gray-800 mr-2">Description:</span>
							{selectedUserSession.purpose.description}
						</p>
					)}
					{selectedUserSession.purpose.summary && (
						<p className="mb-4">
							<span className="font-semibold text-gray-800 mr-2">Summary:</span>
							<span className="text-gray-600">{selectedUserSession.purpose.summary}</span>
						</p>
					)}
					{selectedUserSession.purpose.progress && (
						<p className="mb-4">
							<span className="font-semibold text-gray-800 mr-2">Progress:</span>
							<span
								className={`px-2 py-1 rounded-full text-sm font-medium ${
									selectedUserSession.purpose.progress === "Yes"
										? "bg-green-100 text-green-800"
										: selectedUserSession.purpose.progress === "Partial"
										? "bg-yellow-100 text-yellow-600"
										: selectedUserSession.purpose.progress === "No"
										? "bg-red-100 text-red-800"
										: "bg-blue-100 text-blue-800"
								}`}
							>
								{selectedUserSession.purpose.progress}
							</span>
						</p>
					)}
				</>
			)}
		</ReportCard>
	);
}

type Comment = {
	comment: string;
	priority: "low" | "medium" | "high";
};

export function renderNoteworthyComments(
	selectedUserSession: SessionView,
	userId: string,
	onAddComment?: (content: string, title: string) => void,
	hideButtons?: boolean
) {
	const title = "Noteworthy Comments";
	const initialComment = getInitialComment(selectedUserSession, title);
	const canComment = userId === selectedUserSession.userId && selectedUserSession.isNotSubmitted();

	const renderComment = (comment: Comment | Comment[]) => {
		if (Array.isArray(comment)) {
			return comment.map((c, index) => <li key={index}>"{c.comment}"</li>);
		}
		return <li>"{comment.comment}"</li>;
	};

	return (
		<ReportCard
			title={title}
			icon={<ChatBubbleLeftIcon className="h-5 w-5 text-[#16013e]" />}
			onAddComment={(content, title) => {
				if (onAddComment) {
					onAddComment(content, title);
				}
			}}
			initialComment={initialComment}
			canComment={canComment}
			hideButtons={hideButtons}
		>
			{selectedUserSession.noteworthyComments && (
				<ul className="list-disc pl-5 space-y-1 text-gray-600">
					{selectedUserSession.noteworthyComments.positive && renderComment(selectedUserSession.noteworthyComments.positive)}
					{selectedUserSession.noteworthyComments.negative && renderComment(selectedUserSession.noteworthyComments.negative)}
				</ul>
			)}
		</ReportCard>
	);
}

export function renderNoChatHistory() {
	return (
		<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
			<div>
				<h2 className="text-xl font-semibold text-[#16013e]">Chat History</h2>
				<div className="mt-4 text-gray-600">
					<p>Transcript not included for this session.</p>
				</div>
			</div>
		</div>
	);
}
